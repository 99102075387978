import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Leoparden-Print"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <Typography paragraph={true}>
            Animal-Print ist wieder angesagt. Der extravagante Fake-Fur-Mantel mit Leoparden-Muster
            von Frapp aus Polyester ist dabei ein wahres It-Piece. Nicht zu vergessen ist der Y.A.S
            Kunstpelz Leoparden Mantel! Influencerinnen wie Shanina Mshalik oder auch Romee Strijd
            machen es vor: Sie tragen Leoparden-Muster. Egal, ob abends beim Dinner oder auf der
            Reise in die Wildnis: Leoparden-Print kann man überall tragen. Das Maxikleid mit
            Leoprint und langen Ärmeln sowie Rüschen von Vila ist ein wahrhaftiges Mode-Statement
            für alle Leoparden-Print Liebhaber. Wer die Farbe blau mag, der wird das Midikleid von
            SELECTED FEMME mit blauem Leopardenprint lieben.
          </Typography>
          <InstagramEmbed
            url="https://www.instagram.com/p/B2qpBldnqmC/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25285049065&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36064100?w=1786&h=2500",
                    title: "",
                  },
                ],
                name: "FRAPP",
                price: 139.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608463913&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/30644711?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "Y.A.S",
                price: 139.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25093227383&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35397320?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "Vila",
                price: 79.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25168988923&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35761713?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "Vila",
                price: 64.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608999415&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33636352?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "SELECTED FEMME",
                price: 99.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25628323329&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/bb973e74-49f5-554e-a5f5-b3e90919ce13?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "Vero Moda",
                price: 39.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608999333&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33636202?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "SELECTED FEMME",
                price: 69.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25314692571&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36040386?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "SELECTED FEMME",
                price: 99.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25116666753&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35580795?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "SELECTED FEMME",
                price: 89.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609167137&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34308593?w=2500&h=2500",
                    title: "",
                  },
                ],
                name: "Caterina Lucchi",
                price: 344,
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B268QHnFHdE/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
